import { useMediaQuery, useTheme } from "@mui/material";
import { React, createContext } from "react"


const MyFunctionalContexts = createContext({
    fromContext: "this is from context",
    chooseTitleTextVariant: () => { },
    chooseSubtitleVariant: () => { }, // these are required just for getting hints in the IDE / VS Code; otherwise its of no use
    chooseBodyTextVariant: () => { },
    isDownSm: () => { },
    isDownMd: () => { },
    isUpMd: () => { }
})


export const ContextProvider = (props) => {
    const theme = useTheme();
    const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const isUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const chooseTitleTextVariant = () => {
        if (isDownSm) return "h3";
        if (isDownMd) return "h2";
        if (isUpMd) return "h1";
    }

    const chooseSubtitleVariant = () => {
        if (isDownSm) return "h5";
        if (isDownMd) return "h4";
        if (isUpMd) return "h3";
    }

    const chooseBodyTextVariant = () => {
        if (isDownSm) return "h4";
        if (isDownMd) return "h3";
        if (isUpMd) return "h2";
    }

    return <MyFunctionalContexts.Provider
        value={{
            chooseTitleTextVariant: chooseTitleTextVariant,
            chooseSubtitleVariant: chooseSubtitleVariant,
            chooseBodyTextVariant: chooseBodyTextVariant,
            isDownSm: isDownSm,
            isDownMd: isDownMd,
            isUpMd: isUpMd
        }}
    >
        {props.children}
    </MyFunctionalContexts.Provider>
}

export default MyFunctionalContexts;