import { Box, Button, Paper, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import React from 'react';
// import { useContext } from 'react';
// import MyFunctionalContexts from '../../frameWorks/context/functional-context';
import AboutDetailsModal from './AboutDetailsModal';
import styles from './AboutUs.module.css';

const style = {
    position: 'absolute',
    top: '43%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'transparent',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    color: "white"
};

const CustomizedPaper = styled(Paper)`
background-color: transparent;
color: white;
`;


const AboutUsDetails = (employee) => {
    // const myFunctionalContexts = useContext(MyFunctionalContexts)
    const { name, education, designation, image } = employee.employee;
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    return (
        <CustomizedPaper elevation={3} className={styles.parentContainer}>
            <Box>
                <Box >
                    <center className={styles.container}>
                        <img width="225px" height="225px" src={image} alt="" />
                    </center>
                </Box>
                <Typography align='center' variant="h5" borderBottom={1}>
                    {name}
                </Typography>
                <Typography align='center'>
                    {education}
                </Typography>
                <Typography align='center'>
                    {designation}
                </Typography>
                <center>
                    <Button
                        onClick={handleOpen}
                        variant='contained'
                        color="warning"
                        sx={{
                            margin: '1rem'
                        }}
                    >
                        View Details
                    </Button>
                </center>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {
                    <AboutDetailsModal key={employee.id} employee={employee} style={style} />
                }
            </Modal>
        </CustomizedPaper >
    );
};

export default AboutUsDetails;