import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import AboutUs from './pages/AboutUs/AboutUs';
import ContactUs from './pages/ContactUs/ContactUs';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Projects from './pages/Projects/Projects';
import Footer from './utilities/Footer/Footer';
import AppBarWithDrawer from './utilities/NavBar/NavBar';

function App() {
  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <AppBarWithDrawer />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/About' element={<AboutUs />} />
        <Route path='/Contact' element={<ContactUs />} />
        <Route path='/Projects' element={<Projects />} />
        <Route path='/Login' element={<Login />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
