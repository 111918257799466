import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Box, Typography } from '@mui/material';
import footerBG from "../../assets/workers.jpg"
import React from 'react';

const Footer = () => {
    return (

        <Box style={{
            marginTop: "auto",
            flex: "0 0 250px",
            backgroundImage: `url(${footerBG})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            // backgroundPosition: 'center',
        }} >
            <Typography align='center' gutterBottom variant='h3' color="white">
                Radix Infrastructure LTD.
            </Typography>
            <Typography gutterBottom color='white' sx={{ display: "flex", justifyContent: 'center', gap: 2 }}>
                <FacebookRoundedIcon
                    fontSize="large"
                    onClick={() => window.open('https://www.facebook.com/')}
                />
                <LinkedInIcon
                    fontSize="large"
                    onClick={() => window.open('https://www.linkedin.com/')}
                />
                <TwitterIcon
                    fontSize="large"
                    onClick={() => window.open('https://www.twitter.com/')}
                />
            </Typography>
            <Typography align='center' gutterBottom variant='h6' color='white' >
                email: radixinfraltd@gmail.com; cell: 01762458849 & 01305336996
            </Typography>
            <Typography align='center' gutterBottom variant='h6' color='white' >
                Apartment: H-156 (4th Floor), Road-22, DOHS, Mohakhali,   Dhaka-1206
            </Typography>
            <Typography align='center' gutterBottom variant='subtitle1' color='white' >
                <span style={{ fontSize: 21 }}>&#169;</span> 2022 Radix Infrastructure LTD. All rights reserved
            </Typography>
        </Box>

    );
};

export default Footer;