import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./Project.module.css";

const ProjectDetails = ({ project }) => {
  const { name, image, location, type, year, description, status } = project;
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleShare = async () => {
    try {
      await navigator.share({
        title: name,
        text: description,
        url: window.location.href,
      });
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        maxWidth: "350px",
      }}
    >
      <CardMedia sx={{ height: 200 }} image={image} title={name} />
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography gutterBottom variant="h5" component="div">
          {name}
        </Typography>
        <Typography
          variant="body2"
          color="#5F9EA0"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
          }}
        >
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button onClick={handleShare} size="small">
          Share
        </Button>
        <Button onClick={handleOpenModal} size="small">
          Learn More
        </Button>
      </CardActions>

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="sm"
        fullWidth
        classes={{ paper: styles.dialogPaper }}
        PaperProps={{
          sx: {
            backgroundColor: "#2F4F4F",
            boxShadow: "none",
            color: "white",
          },
        }}
      >
        <DialogTitle>
          {name} ({status})
        </DialogTitle>
        <DialogContent
          dividers
          sx={{ backgroundColor: "rgba(255, 255, 255, 0.3)" }}
        >
          <DialogContentText sx={{ color: "white" }}>
            {description}
          </DialogContentText>
          <CardMedia
            component="img"
            src={image}
            alt={name}
            sx={{ maxWidth: "100%", height: "auto", mt: 2 }}
          />
          <Typography variant="body2" sx={{ mt: 2, color: "white" }}>
            Location: {location}
            <br />
            Type: {type}
            <br />
            {year !== "" ? `Year: ${year}` : ""}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Close</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default ProjectDetails;
