import { Box, Typography } from '@mui/material';
import React, { useContext } from 'react';
import MyFunctionalContexts from '../../frameWorks/context/functional-context';
import WaveDownwards from './backgrounds/WaveDownwards';
import styles from './Home.module.css'



const Introduction = () => {
    const myFunctionalContexts = useContext(MyFunctionalContexts)

    return (
        <>
            <WaveDownwards />

            <Box>
                <Typography variant={myFunctionalContexts.chooseBodyTextVariant()} color="warning.main" align='center'>
                    Who are we?
                </Typography>
            </Box>
            <Box className={styles.parentBox}>
                <Box className={styles.paragraphText}>
                    <Typography color="white">
                        <span className={styles.companyName}>Radix infrastructure Limited</span> was established in 5th August, 2021aiming to create a unique construction organization in Bangladesh and beyond. Commitment and knee management skill have taken this organization to one of the leading construction organizations within last couple of years.
                    </Typography>
                    <Typography color="white">
                        <span className={styles.companyName}>Radix infrastructure Limited</span> is one of the most prestigious and experienced construction conglomerates in Bangladesh today. It is now one of the pioneer construction companies in Bangladesh from the incipient period after establishment.
                    </Typography>
                    <Typography color="white">
                        Keeping pace with rapid economic& technological changes followed by the policy of Globalization It has outspread their business activities within a short period of time and has been able to emerge as a promoter of national standard of business houses and industrial enterprises founded on the principles of construction integrity with the high capable technical personnel and professionals.
                    </Typography>
                    <Typography color="white">
                        Since inception, <span className={styles.companyName}>Radix infrastructure Limited</span> has been actively contributing to the national as well as global economy by way of effective utilization of resources and creating employment opportunities.
                    </Typography>
                </Box>
                <Box className={styles.paragraphText}>
                    <Typography color="white">
                        <span className={styles.companyName}>Radix infrastructure Limited</span> is now operating in different type construction and consulting arenas. We have started our business with construction infrastructure works. Beside this now we are proud to notice that we also offer different types of engineering work like Water, Gas, Power, Commissioning, Road, Bridge and Culvert, Building construction with several departments, supply works etc. Now we are the pioneer of pipeline work in Bangladesh, specially Water, Gas, Telecom etc.
                    </Typography>
                    <Typography color="white">
                        Beside this we also have an independent consultancy unit, computerized design section, IT team, Civil, Electrical, and Mechanical & Agricultural engineering team. It is now operating with many vital enterprises in Bangladesh of highly esteemed commercial and technological Enterprises in the field of construction ventures and commercial enterprises.
                    </Typography>
                    <Typography color="white">
                        Presently more than 25 people are employed in our renowned business sector. Besides, through its multidirectional economic activities. It has also created employment opportunities to our organization as well as Bangladesh.
                    </Typography>
                    <Typography color="white">
                        Through forward and backward linkage, it has encouraged development of various industrial and commercial units in the country. With its expertise in different fields and extensive human resources <span className={styles.companyName}>Radix infrastructure Limited</span> has undertaken various projects and now-a-days become one of the leading construction organization in Bangladesh.
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default Introduction;