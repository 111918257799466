import { Box } from "@mui/material";
import React from "react";
import ProjectDetails from "./ProjectDetails";
import inseerah from "../../assets/projects/ongoing/inseerah.png";
import iramahal from "../../assets/projects/ongoing/iramahal.png";
// import jamtola from "../../assets/projects/ongoing/jamtola.jpeg";
import rashida from "../../assets/projects/ongoing/rashida.png";

export const projects = [
  {
    id: 1,
    name: "Insheerah Mahmuda Villa",
    type: "Multistoried Apartment",
    year: 2019,
    location: "Shantinagar, Dhaka",
    image: inseerah,
    status: "Ongoing",
    description:
      "A luxurious 7-story apartment in Shantinogor, Dhaka. Designed in 2019, this stunning residence offers opulent living spaces and modern amenities. With a site area of 4.15 katha and a built area of 1403 sqm, Insheerah Mahmuda Villa provides a harmonious and comfortable living experience in a prime location. Experience the epitome of contemporary urban living at Insheerah Mahmuda Villa.",
  },
  {
    id: 2,
    name: "Iramohol",
    type: "Multistoried Apartment",
    year: 2022,
    location: "Basundhara R/A, Dhaka",
    image: iramahal,
    status: "Ongoing",
    description:
      "Iramohol is a magnificent 10-story apartment building located in the prestigious Basundhara Residential Area, Block-M. Designed in 2022, this architectural masterpiece offers luxurious living spaces in a prime location. With a total site area of 10 katha and a built area of 3566 square meters, Iramohol features striking contemporary design, opulent interiors, and a range of amenities. Experience the epitome of modern urban living at Iramohol.",
  },
  {
    id: 3,
    name: "Rashida Villa",
    type: "Multistoried Apartment",
    year: "",
    location: "Tangail",
    image: rashida,
    status: "Completed",
    description:
      "Rashida Villa, located in Tangail, is an impressive three-story building with a site area of 6650 square feet. Each floor boasts a spacious floor area of 1985 square feet, providing ample room for comfortable living. This architectural gem seamlessly combines traditional and modern elements, creating an elegant and inviting space. With its prime location and thoughtful design, Rashida Villa offers a refined and luxurious living experience in the heart of Tangail.",
  },
];

const Projects = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "1rem",
        gap: "1rem",
      }}
    >
      {projects.map((project) => (
        <ProjectDetails key={project.id} project={project} />
      ))}
    </Box>
  );
};

export default Projects;
