import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import mahbub from "../../assets/about/mahbub.jpg";
import tawheed from "../../assets/about/tawhid.jpg";
import tazbir from "../../assets/about/tazbir.jpg";
import yakub from "../../assets/about/yeakub.jpg";
import MyFunctionalContexts from "../../frameWorks/context/functional-context";
import styles from "./ContactUs.module.css";
import Gmap from "./Gmap";

const ContactUs = () => {
  const myFunctionalContexts = useContext(MyFunctionalContexts);
  const members = [
    {
      id: 1,
      designation: "Director Admin",
      email: "admin@radixinfrastructure.com",
      name: "Mst. Tazbir-E-Nur",
      image: tazbir,
      mobile: "+880 1769-666444",
    },
    {
      id: 2,
      designation: "Director Execution",
      email: "director@radixinfrastructure.com",
      name: "Engr. Md. Tawhidul Islam ",
      image: tawheed,
      mobile: "+880 1725-999319",
    },
    {
      id: 3,
      designation: "CEO",
      email: "ceo@radixinfrastructure.com",
      name: "Md. Yeakub Hossain",
      image: yakub,
      mobile: "+880 1716-457298",
    },
    {
      id: 4,
      designation: "Managing Director",
      email: "md@radixinfrastructure.com",
      name: "Engr. Md. Mahbub Alam",
      image: mahbub,
      mobile: "+880 1762-458849",
    },
  ];
  return (
    <>
      <Box className={styles.parentContainer}>
        <Typography
          sx={{ marginBottom: 4 }}
          variant={myFunctionalContexts.chooseBodyTextVariant()}
          color="warning.main"
        >
          RADIX INFRASTRUCTURE LIMITED (RIL)
        </Typography>
        <Typography variant="h5" color="white">
          Apartment: H-156 (4th Floor), Road-22, DOHS, Mohakhali, Dhaka-1206
        </Typography>
        <Typography variant="h5" color="white" sx={{ marginBottom: 4 }}>
          Mobile: 01762458849 & 01305336996
        </Typography>
        <Box className={styles.showContactCardGroup}>
          {members.map((member) => (
            <Card key={member.id} member={member} />
          ))}
        </Box>
      </Box>
      <Gmap />
    </>
  );
};

const Card = (props) => {
  const { name, image, email, designation, mobile } = props.member;

  return (
    <Box className={styles.individualCard}>
      <Box>
        <img src={image} width="200px" height="200px" alt="" />
      </Box>
      <Typography color="coral" variant="h5">
        {name}
      </Typography>
      <Typography color="white" variant="h6">
        {designation}
      </Typography>
      <Typography color="white" variant="h6">
        {email}
      </Typography>
      <Typography color="white" variant="h6">
        {mobile}
      </Typography>
    </Box>
  );
};

export default ContactUs;
