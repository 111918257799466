import { Box, Typography } from '@mui/material';
import React from 'react';

const AboutDetailsModal = ({ style, employee }) => {
    const { name, education, designation, image, description } = employee.employee;
    return (
        <Box sx={style}>
            <Box >
                <center>
                    <img width="225px" height="225px" src={image} alt="" />
                </center>
            </Box>
            <Typography align='center' variant='h4'>
                {name}
            </Typography>
            <Typography align='center'>
                {education}
            </Typography>
            <Typography align='center'>
                {designation}
            </Typography>
            <br />
            <Typography align='justify'>
                {description}
            </Typography>
        </Box>
    );
};

export default AboutDetailsModal;