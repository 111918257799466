import { createTheme, responsiveFontSizes } from "@mui/material";
import palette from "./palette";
import shadows, { customShadows } from "./shadows";


 /* export let theme = createTheme({
    palette,
    shadows,
    customShadows,
    typography: {
        body2: {
            color: "#2E3B52",
            fontWeight: 400,
            fontSize: 14,
        },
        fontSize: 12,
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Arial',
            'sans-serif',
        ].join(','),
        subtitle1: {
            fontWeight: 600,
        }
    },
})


theme.overrides = {
    MuiButton: {
        containedPrimary: {
            height: theme.spacing(5),
            width: theme.spacing(222),
            color: "red",
            [theme.breakpoints.down("md")]: {
                width: theme.spacing(2),
            }
        },
        outlinedPrimary: {
            height: theme.spacing(5),
            width: theme.spacing(22),
        },
        textPrimary: {
            color: theme.palette.primary.dark
        }
    },
};
  */

 export let theme = createTheme({
    palette,
    shadows,
    customShadows,
    typography: {
        body2: {
            color: "#2E3B52",
            fontWeight: 400,
            fontSize: 14,
        },
        // Use the system font instead of the default Roboto font.
        fontSize: 12,
        fontFamily: [
            'Texturina', 'Sacramento', 'cursive', 'serif'
        ].join(','),
        subtitle1: {
            fontWeight: 600,
        }
    },
    components: {
        /* MuiButton: {
            styleOverrides: {
                colorInherit: {
                    backgroundColor: "#d50000",
                },
                defaultProps: {
                    color: "inherit",
                },
            }
        } */
        /*   MuiCssBaseline: {
              styleOverrides: `
                @font-face {
                  font-family: 'Sacramento';
                  font-style: normal;
                  font-display: swap;
                  font-weight: 400;
                  src: local('Sacramento'), local('Sacramento-Regular'), url(${sacramento}) format('ttf');
                  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                }
              `,
            },*/
    }
});

theme = responsiveFontSizes(theme);
export default theme;