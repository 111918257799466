import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useContext } from 'react';
import MyFunctionalContexts from '../../frameWorks/context/functional-context';
import WaveUpwards from './backgrounds/WaveUpwards';
import styles from "./Home.module.css";

const VisionAndMission = () => {
    const myFunctionalContexts = useContext(MyFunctionalContexts);

    return (
        <>
            <Typography variant={myFunctionalContexts.chooseBodyTextVariant()} color="warning.main" align='center'>
                Why are we?
            </Typography>
            <Box className={styles.parentBox}>
                <Box className={styles.paragraphText}>
                    <Typography variant={myFunctionalContexts.chooseSubtitleVariant()} color="warning.main" align='center'>
                        Our Vision
                    </Typography>
                    <Typography color="white">
                        To be a pre-eminent Construction and consultant in Bangladesh providing total customer care that the business and professional communities hold in the highest regard.
                    </Typography>
                </Box>
                <Box className={styles.paragraphText}>
                    <Typography variant={myFunctionalContexts.chooseSubtitleVariant()} color="warning.main" align='center'>
                        Our Mission
                    </Typography>
                    <Typography color="white">
                        Radix Infrastructure Limited. Mission is to provide total customer care and satisfaction as to ensure significant contribution to improve clients’ business performance. We will achieve market place leadership by building business with best in-class capabilities to deliver measurable results for our clients.
                    </Typography>
                </Box>
            </Box>
            <Box className={styles.parentBox}>
                <Box className={styles.paragraphText}>
                    <Typography variant={myFunctionalContexts.chooseSubtitleVariant()} color="warning.main" align='center'>
                        Why RIL?
                    </Typography >
                    <Typography color="white">
                        RIL is confident of being able to provide the most efficient, cost effective solution for its customer.
                    </Typography>
                    <Typography color="white">
                        Delivers what is promised to its’ customers and more. Over the year built relationships not only with the customers but also with leading material suppliers. Thus bring the right solution to the customers. This has not been the only critical success for the company. Bring new meaning to the phrase” Committed to Excellence….”Emphasis to provide total customer satisfaction has been the culture cultivated over the years by the company and its’ dedicated and knowledgeable staff. As we are interested in establishing mutually beneficial log-term relationship with our customers, we seek not only to satisfy their immediate requirements also to anticipate their future needs. We look forward to grow with our customers, as their successes are our tools.
                    </Typography>
                </Box>
                <Box className={styles.paragraphText}>
                    <Typography variant={myFunctionalContexts.chooseSubtitleVariant()} color="warning.main" align='center'>
                        What do we focus on?
                    </Typography>
                    <Box color="white" sx={{ fontFamily: "Texturina,Sacramento,cursive,serif", fontSize: "small" }}>
                        <ul>
                            <li>Civil construction works.</li>
                            <li>Steel Structure.</li>
                            <li>Mechanical, Electrical & Plumbing works.</li>
                            <li>Interior solutions</li>
                            <li>Detailed Engineering Analysis (DEA)</li>
                            <li>Retrofitting</li>
                            <li>Master planning</li>
                            <li>Sub-Soil Investigation (Soil Test)</li>
                            <li>Load Test</li>
                            <li>Pile Integrity Test (PIT)</li>
                            <li>Digital Survey</li>
                        </ul>

                    </Box>
                </Box>
            </Box >
            <WaveUpwards />
        </>
    );
};

export default VisionAndMission;