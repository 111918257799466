import { Box } from "@mui/material";
import React from "react";
import styles from "./ContactUs.module.css";

const Gmap = () => {
  return (
    <Box style={{ padding: 15, display: "flex", justifyContent: "center" }}>
      <div className={styles.mapContainer}>
        <iframe
          title="Location of our Office"
          /* style={{ filter: "invert(100%)" }} */
          src="https://maps.google.com/maps?width=100%&amp;height=440&amp;hl=en&amp;q=House-156%2C%20Road-22%2C%20DOHS%2C%20Mohakhali%2C%20%20%20Dhaka-1206+(Location%20of%20our%20Office)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ></iframe>
      </div>
    </Box>
  );
};

export default Gmap;
