// import { Typography } from '@mui/material';
import { Box } from "@mui/material";
import React from "react";
import mahbub from "../../assets/about/mahbub.jpg";
import tazbir from "../../assets/about/tazbir.jpg";
import tawhid from "../../assets/about/tawhid.jpg";
import yeakub from "../../assets/about/yeakub.jpg";
import AboutUsDetails from "./AboutUsDetails";

const AboutUs = () => {
  const employees = [
    {
      id: 1,
      name: "Mst. Tazbir-E-Nur",
      education: "Masters in Management, Dhaka University",
      designation: "Director Admin",
      image: tazbir,
      description:
        "I am a Master's degree holder in Management from Dhaka University. With 10 years of experience in the field of Construction Administration, I have specialized in overseeing Project Management and Contract Administration, among other responsibilities. Throughout my decade-long tenure in this field, I have effectively managed and supervised various activities, including Project Planning, Scheduling, Budgeting, and Quality Control. My extensive knowledge and skills in this domain make me a valuable asset to the company as a Construction Administrator. Furthermore, my wide network of friends greatly contributes to the overall growth and expansion of the company's market area.",
    },
    {
      id: 2,
      name: "Engr. Md. Tawhidul Islam ",
      education: "B.Sc. in Civil Engineering, RUET",
      designation: "Director Execution",
      image: tawhid,
      description:
        "I am a Civil Engineer, having graduated from Rajshahi University of Engineering Technology (RUET). With 10 years of experience in the fields of Construction, Project Management, and Designing, I have been actively involved in various levels of activities within my industry. Over the course of a decade, I have consistently demonstrated successful management and operation of these activities. My extensive knowledge in this field is a valuable asset to the company I work for. Moreover, my wide network of friends plays a crucial role in the overall growth and expansion of the company's market area.",
    },
    {
      id: 3,
      name: "Md. Yeakub Hossain",
      education: "Masters in Geography, Dhaka University",
      designation: "CEO",
      image: yeakub,
      description:
        "I hold a Master of Science degree in Geography & Environment from Dhaka University, and I have accumulated 14 years of experience in Urban Development and Planning, Environmental Studies, and Socio-cultural Aspects. Throughout my career, I have excelled in writing project proposals, conducting research, developing training materials, and managing project cycles. My strong communication and networking skills enable effective collaboration with government entities and institutions. I take pride in the contributions of my expertise and knowledge, which greatly contribute to the growth and expanding market area of the company.",
    },
    {
      id: 4,
      name: "Engr. Md. Mahbub Alam",
      education: "MSc in Civil Engineer (Structure), MIST",
      designation: "Managing Director",
      image: mahbub,
      description:
        "I am a Civil Engineer with a Master's degree from the Military Institute of Science & Technology (MIST) and a Bachelor's degree from Rajshahi University of Engineering Technology (RUET). I have accumulated around 15 years of experience working in the construction, project management, and designing sectors. Over the past decade, I have successfully managed and operated various activities at different levels. My extensive knowledge in this field serves as a valuable asset to the company. Additionally, my wide network of friends greatly contributes to the overall growth and expansion of the company's market area.",
    },
  ];
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "1rem",
      }}
    >
      {employees.map((employee) => (
        <AboutUsDetails key={employee.id} employee={employee} />
      ))}
    </Box>
  );
};

export default AboutUs;
