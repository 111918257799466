import { Box, Typography } from '@mui/material';
import React, { useContext } from 'react';
import MovingComponent from 'react-moving-text';
import heroImage from '../../assets/constructions.jpg';
import MyFunctionalContexts from '../../frameWorks/context/functional-context';
import Introduction from './Introduction';
import VisionAndMission from './VisionAndMission';

const Home = () => {
    const myFunctionalContexts = useContext(MyFunctionalContexts)

    return (
        <>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                backgroundImage: `url(${heroImage})`,
                backgroundSize: "cover",
                height: 400,
                backgroundRepeat: 'no-repeat',
            }}>
                <Box>
                    <Typography variant={myFunctionalContexts.chooseTitleTextVariant()} sx={{ fontFamily: "Texturina" }} color="white">
                        Radix Infrastructure Ltd.
                    </Typography>
                    <Typography variant={myFunctionalContexts.chooseSubtitleVariant()} color="info.light">
                        <MovingComponent type="typewriter"
                            dataText={[
                                'Engineering...',
                                'Consultation...',
                                'Construction...',
                                'Importing...',
                                'Equipment Renting...',
                            ]} />
                    </Typography>

                </Box>
            </Box>
            <Introduction />
            <VisionAndMission />
        </>
    );
};

export default Home;