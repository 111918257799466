import React, { useState } from "react";
import {
  Avatar,
  Button,
  Container,
  TextField,
  Typography,
  Box,
  Link,
  Grid,
  InputAdornment,
  IconButton,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const LoginPage = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Add login logic here
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ backgroundColor: "transparent" }}
    >
      {/* <CssBaseline /> */}
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          //   border: "2px solid #ffffff",
          //   borderRadius: "10px",
          padding: "2rem",
          border: "2px solid #fff",
          borderColor: "#fff",
          borderImage: "linear-gradient(-45deg, #3498db, #e67e22) 1",
          borderRadius: "40px",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" color="white">
          Sign in
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="email"
            label="Email Address"
            type="email"
            id="email"
            placeholder="Input email"
            // autoComplete="email"
            // autoFocus
            InputLabelProps={{
              style: { color: "#ffffff" },
            }}
            InputProps={{
              style: { color: "#ff0000" },
            }}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ffffff", // Red color
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ffffff", // Red color on hover
              },
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            placeholder="Input Password"
            // autoComplete="current-password"
            InputLabelProps={{
              style: { color: "#ffffff" },
            }}
            InputProps={{
              style: { color: "#ff0000" },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{ color: "white" }}
                    onClick={handleShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ffffff", // Red color
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ffffff", // Red color on hover
              },
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPage;
